.transfer {
  @apply my-auto me-4 cursor-pointer border border-transparent hover:border-[#A8BAD7] rounded-[6px];
}

.transfer-selector {
  @apply flex flex-col;

  .trigger {
    @apply flex bg-white px-2 py-3 leading-none text-black text-[12px] h-[40px] w-full items-center justify-between rounded-md border border-black/10 ;
  }

  .item-selected{
    @apply flex text-[14px] font-normal;
  }
}

.selector-content {
  @apply rounded-md border bg-white z-50 w-full overflow-auto;

  .item {
    @apply flex text-[14px] font-normal cursor-pointer px-4 py-2;
  }

  .item-selected {
    @apply bg-[#EDEDED];
  }
}