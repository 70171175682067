
.table-check-users {
    th {
        @apply font-bold;
    }
    .first-column {
        @apply px-8 bp1:px-3;
    }
    .first-column-left {
        @apply ps-8 bp1:ps-3;
    }

    .with-children {
        @apply cursor-pointer;
    }

    tbody {
        tr.collapsed td {
            background-color: #F6F7FC;
        }

        tr.collapsed td:first-child {
            background-color: #F6F7FC;
            border-left-width: 3px;
        }

        tr.collapsed td:first-child div {
            margin-left: -1.5px;
        }

        tr.collapsed td:first-child::before {
            content: '';
            position: absolute;
            left: -3px;
            bottom: -1px;
            width: 3px;
            height: 1px;
        }
    }
}

.stats-tab {
    @apply flex w-full flex-row gap-2 text-[15px];
    @apply bp1:flex-row bp1:overflow-y-hidden;
    @apply bp1:h-[fit-content] bp1:w-full bp1:overflow-x-auto bp1:px-1 bp1:py-1;
    @apply bp1:items-center;
    @apply bp1:w-full bp1:max-w-full;

    .tab-item {
        @apply bp1:w-[fit-content] bp1:shrink-0 bp1:justify-center bp1:space-x-0 overflow-hidden;
        @apply relative flex items-center justify-center rounded-3xl px-5 py-2;

        svg {
            @apply bp1:hidden;
        }
    }
}

.stats-content {
    @apply flex w-full flex-col p-0
}

.t-stats-title {
 @apply min-w-[150px] text-start;
}
.t-stats-viewed {
    @apply min-w-[200px] text-start;
}
.t-stats-interaction {
    @apply min-w-[150px] text-start;
}
.t-stats-incidence {
    @apply min-w-[100px] text-start;
}
.t-stats-difficulty {
    @apply min-w-[100px] text-start;
}
.t-stats-goto {
    @apply min-w-[70px] text-start;
}
.t-stats-answered {
    @apply min-w-[120px] text-start;
}
.t-stats-average-score {
    @apply min-w-[100px] text-start;
}
.t-stats-best-score {
    @apply min-w-[100px] text-start;
}
.t-stats-succeeded {
    @apply min-w-[100px] text-start;
}