.card-container {
  @apply cursor-pointer min-h-[160px] max-w-[400px] min-w-[160px] rounded-[16px] bg-white flex flex-col relative items-center p-4 gap-3 py-10 border-2 border-transparent hover:border-blue-secondary;
  @apply dark:bg-d-gray-light dark:text-d-text dark:hover:border-white;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.05);

  &.active {
    @apply border-blue-secondary dark:border-white;
  }

  &.disabled {
    @apply hover:cursor-not-allowed;
  }

  h1 {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}