.notification-title {
  @apply flex flex-col text-[25px] font-semibold text-[#5368E5] px-2 pb-3 pt-1 mx-auto text-center;
}

.notification-body {
  @apply flex flex-col text-[14px] font-normal text-[#0E0E0E] px-2 py-3 justify-center;

  a,
  span,
  div,
  p {
    @apply text-center;
  }

  img, video {
    @apply mx-auto rounded-[8px];
  }
  .button-container {
    @apply flex justify-center;
  }
  button {
    @apply w-fit px-6 mx-auto;
  }

  .video-custom {
    @apply relative w-full min-h-[300px] h-[300px] gap-10;

    iframe {
      @apply absolute border-0 top-0 w-full h-full;
    }
  }
}